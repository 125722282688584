/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import Div100vh from "react-div-100vh";
import { graphql, Link } from "gatsby";
import { AppContext } from "~context/AppContext";
import { AWSContext } from "~context/AWSContext";
import AlphaVideo from "~components/AlphaVideo";
import Layout from "~components/Layout";
import RotatingButtonLink from "~components/RotatingButtonLink";
import SEO from "~components/SEO";
import { fancyLog, shuffleArray } from "~utils/helpers";

class QuizPageComponent extends Component {
  state = {
    answers: {
      comfort: 0,
      ease: 0,
      fun: 0,
      health: 0
    },
    answered: 0,
    backgroundColor: `forest`,
    brightness: 1,
    complete: false,
    contrast: 1,
    questionIndex: 0,
    time: 60
  };

  intervals = [];

  questions = [];

  spreadsheetRows = [];

  componentDidMount() {
    const { appContext, navigate } = this.props;

    appContext.setActivePath(`/quiz`);
    appContext.setShareUrl(`https://www.myfoodie.id/start`);

    this.setQuestions();

    this.intervals.push(
      setInterval(() => {
        this.countdown();
      }, 1001)
    );
  }

  componentWillUnmount() {
    this.clearIntervals();
  }

  clearIntervals = () => {
    if (!this.intervals || !this.intervals.length) {
      return;
    }

    this.intervals.forEach(interval => {
      clearInterval(interval);
    });
  };

  //

  answer = (question, affirmative) => {
    const { answers } = this.state;

    this.spreadsheetRows.push({
      Question: question.question,
      Answer: affirmative
    });

    if (question.effect !== `none`) {
      let effect = -1;

      if (affirmative) {
        effect = 1;

        if (question.core) {
          effect = 2;
        }
      } else if (question.core) {
        effect = -2;
      }

      answers[question.effect] += effect;
    }

    this.setState(prevState => ({
      answered: prevState.answered + 1,
      answers
    }));

    //

    if (this.state.questionIndex >= this.questions.length - 1) {
      this.finish();
    }

    this.setState(prevState => ({
      questionIndex: prevState.questionIndex + 1
    }));
  };

  countdown = () => {
    if (this.state.time <= 1) {
      this.finish();
    }

    switch (this.state.time) {
      case 56:
        this.setState({
          backgroundColor: `light-green`,
          brightness: 1,
          contrast: 1
        });

        break;

      case 41:
        this.setState({
          backgroundColor: `drab`,
          brightness: 0.9,
          contrast: 1.2
        });

        break;

      case 33:
        this.setState({
          backgroundColor: `mandarin`,
          brightness: 0.8,
          contrast: 1.4
        });

        break;

      case 26:
        this.setState({
          backgroundColor: `satsuma`,
          brightness: 0.7,
          contrast: 1.6
        });

        break;

      case 19:
        this.setState({
          backgroundColor: `orange`,
          brightness: 0.6,
          contrast: 1.8
        });

        break;

      case 11:
        this.setState({
          backgroundColor: `dark-orange`,
          brightness: 0.55,
          contrast: 1.9
        });

        break;

      case 2:
        this.setState({
          backgroundColor: `red`,
          brightness: 0.5,
          contrast: 2
        });

        break;

      default:
        break;
    }

    this.setState(prevState => ({
      time: prevState.time - 1
    }));
  };

  finish = () => {
    this.clearIntervals();

    this.setState({
      complete: true
    });

    if (
      typeof window !== `undefined` &&
      !window.location.href.includes(`localhost`) &&
      this.spreadsheetRows[0]
    ) {
      fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/write-to-google-sheet/`, {
        body: JSON.stringify({ rows: this.spreadsheetRows }),
        method: `POST`
      })
        .then(response => {
          // eslint-disable-next-line no-console
          // console.log(`[google]: `, response);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }

    //

    const { appContext, awsContext, personas } = this.props;

    let comfort = true;
    if (this.state.answers.comfort <= 0) {
      comfort = false;
    }

    let ease = true;
    if (this.state.answers.ease <= 0) {
      ease = false;
    }

    let fun = true;
    if (this.state.answers.fun <= 0) {
      fun = false;
    }

    let health = true;
    if (this.state.answers.health <= 0) {
      health = false;
    }

    this.setState({
      answers: {
        comfort,
        ease,
        fun,
        health
      }
    });

    //

    let persona;
    let personaTitle = ``;

    // TODO : improve
    if (ease && !health && !fun && !comfort) {
      personaTitle = `Whole Foodie`;
    } else if (ease && health && !fun && !comfort) {
      personaTitle = `Refueller`;
    } else if (ease && !health && fun && !comfort) {
      personaTitle = `Big Tipper`;
    } else if (ease && !health && !fun && comfort) {
      personaTitle = `Flavor Townie`;
    } else if (ease && health && fun && !comfort) {
      personaTitle = `Everyday Epicurean`;
    } else if (ease && health && !fun && comfort) {
      personaTitle = `Moodie Foodie`;
    } else if (ease && !health && fun && comfort) {
      personaTitle = `Big Tipper`;
    } else if (ease && health && fun && comfort) {
      personaTitle = `Everyday Epicurean`;
    } else if (!ease && !health && !fun && !comfort) {
      personaTitle = `Whole Foodie`;
    } else if (!ease && health && !fun && !comfort) {
      personaTitle = `Refueller`;
    } else if (!ease && !health && fun && !comfort) {
      personaTitle = `Gastronaut`;
    } else if (!ease && !health && !fun && comfort) {
      personaTitle = `Flavor Townie`;
    } else if (!ease && health && fun && !comfort) {
      personaTitle = `Foodfluencer`;
    } else if (!ease && health && !fun && comfort) {
      personaTitle = `Moodie Foodie`;
    } else if (!ease && !health && fun && comfort) {
      personaTitle = `Gastronaut`;
    } else {
      personaTitle = `Foodfluencer`;
    }

    personas.forEach(({ node }) => {
      if (persona) {
        return;
      }

      if (personaTitle === node.frontmatter.title) {
        persona = node;
      }
    });

    // async; doesn't interrupt app render flow
    awsContext.saveQuizResult(personaTitle).then(() => {
      fancyLog(`Result saved`);
    });

    appContext.setQuizResult(persona);
    appContext.setQuizState(1);
  };

  setQuestions = () => {
    let propsQuestions = [];

    this.props.frontmatter.questions.forEach(question => {
      propsQuestions.push(question);
    });

    propsQuestions = shuffleArray(propsQuestions);

    const questions = [];
    let coreCount = 0;

    propsQuestions.forEach(question => {
      if (coreCount >= 4) {
        return;
      }

      if (question.core) {
        coreCount += 1;
        questions.push(question);
      }
    });

    propsQuestions.forEach(question => {
      if (!questions.includes(question)) {
        questions.push(question);
      }
    });

    this.questions = questions;
  };

  //

  render() {
    const { frontmatter } = this.props;

    const {
      backgroundColor,
      brightness,
      complete,
      contrast,
      questionIndex,
      time
    } = this.state;

    return (
      <Div100vh className="relative">
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.seoDescription}
          frontmatterKeywords={frontmatter.seoKeywords}
        />

        <Layout
          className={`quiz-page h-full flex items-center justify-center overflow-hidden ${
            complete ? `bg-forest` : `bg-${backgroundColor}`
          }`}
          outerClassName="w-full h-full relative"
          footerClass="white"
          footerType="desktop-only"
          headerClass="white"
        >
          <section className="quiz-page__inner grid items-stretch relative">
            <div
              className="quiz-page__timer animation-appear grid-end-10 grid-start-2 flex items-center justify-center text-white"
              style={{ animationDelay: `300ms` }}
            >
              <div
                className={`quiz-page__video absolute ${
                  complete ? `complete` : ``
                }`}
                style={{
                  filter: `${
                    complete
                      ? `brightness(1) contrast(1)`
                      : `brightness(${brightness}) contrast(${contrast})`
                  }`
                }}
              >
                <AlphaVideo
                  className="w-full xs:w-3/4 relative"
                  src="/uploads/alpha-video-4.mp4"
                />
              </div>

              {!complete && (
                <h2 className="big-number relative z-10 text-white">{time}</h2>
              )}
            </div>

            {(complete && (
              <div className="quiz-page__complete grid-end-10 grid-start-14 flex flex-col items-center justify-center">
                <h2 className="quiz-page__complete__text pointer-events-none select-none f1 text-center text-white">
                  Stick a fork in it, you’re done
                </h2>

                <div className="quiz-page__finish animation-appear block relative mx-auto mt-12">
                  <RotatingButtonLink
                    className="infinispin relative"
                    filled
                    path="/results"
                    text="Show results"
                  />
                </div>
              </div>
            )) || (
              <article className="quiz-page__questions grid-end-10 grid-start-14 flex flex-1 flex-col justify-between">
                {this.questions[questionIndex] && (
                  <>
                    <h3 className="b1 text-center text-white">
                      Question #{questionIndex + 1}
                    </h3>

                    <h2 className="quiz-page__question f3 text-center text-white">
                      {this.questions[questionIndex].question}
                    </h2>

                    <div className="flex justify-between relative">
                      <button
                        type="button"
                        className="quiz-page__question__button button button--fancy relative block f3"
                        onClick={() =>
                          this.answer(this.questions[questionIndex], true)
                        }
                      >
                        <div className="button__inner w-full h-full relative block pt-1 pb-5 xs:pt-6 xs:pb-7">
                          Yes
                        </div>
                      </button>

                      <button
                        type="button"
                        className="quiz-page__question__button button button--fancy relative block f3"
                        onClick={() =>
                          this.answer(this.questions[questionIndex], false)
                        }
                      >
                        <div className="button__inner w-full h-full relative block pt-1 pb-5 xs:pt-6 xs:pb-7">
                          No
                        </div>
                      </button>
                    </div>

                    <Link
                      to="/start"
                      className="quiz-page__questions__navigator relative mt-2 mb-4 py-2"
                    >
                      <h3 className="b3 text-center text-white underline">
                        Start over
                      </h3>
                    </Link>
                  </>
                )}
              </article>
            )}
          </section>
        </Layout>
      </Div100vh>
    );
  }
}

const QuizPage = ({ data, navigate }) => {
  const appContext = useContext(AppContext);
  const awsContext = useContext(AWSContext);
  const { frontmatter } = data.markdownRemark;
  const personas = data.allMarkdownRemark.edges;

  return (
    <QuizPageComponent
      appContext={appContext}
      awsContext={awsContext}
      frontmatter={frontmatter}
      navigate={navigate}
      personas={personas}
    />
  );
};

export default QuizPage;

export const quizPageQuery = graphql`
  query QuizPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        questions {
          question
          core
          effect
        }
        rainingImages {
          image {
            childImageSharp {
              fluid(maxWidth: 256) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "persona-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tagline
            description
            dinnerDate
            dinnerDateText
            colorClasses
            videoPoster {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
